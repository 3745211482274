import { render, staticRenderFns } from "./active.column.header.vue?vue&type=template&id=270be180&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../tmp/ffsecurity-ui/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports