





















import Vue from 'vue';
import Component from 'vue-class-component';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import StatusColumnHeader from './status.column.header.vue';
import DistanceColumnHeader from '@/components/counters/distance.column.header.vue';
import ActiveColumnHeader from '@/components/counters/active.column.header.vue';

import { TableComponentNames } from '@/components/tables/components/names';
import { CounterCheckboxEventType } from '@/components/tables/components/generator.counter.checkbox.vue';
import { CounterChartClickEventType } from '@/components/tables/components/generator.counter.chart.vue';

@Component({
  name: 'page-counters',
  components: { PageLayout, TableGenerator, TableNavigation }
})
export default class Counters extends Vue {
  updateInterval: number | undefined;

  get schema(): ITableColumn[] {
    return [
      {
        label_i18n: 'id',
        prop: 'id',
        width: 60
      },
      {
        label_i18n: 'image',
        component: TableComponentNames.CounterRecordImage,
        width: 160
      },
      {
        label_i18n: 'counter_type',
        formatter: this.counterTypeFormatter,
        width: 160,
        align: 'center'
      },
      {
        label_i18n: 'faces',
        prop: 'now_faces',
        width: 160,
        align: 'center'
      },
      {
        label_i18n: 'silhouettes',
        prop: 'now_bodies',
        width: 160,
        align: 'center'
      },
      {
        label_i18n: 'cars',
        prop: 'now_cars',
        width: 160,
        align: 'center'
      },
      {
        'render-header': (h, { column }) => {
          return h(DistanceColumnHeader);
        },
        formatter: this.distanceFormatter,
        width: 160
      },
      {
        'render-header': (h, { column }) => {
          return h(StatusColumnHeader);
        },
        component: TableComponentNames.CounterStatus
      },
      {
        label_i18n: 'name',
        component: TableComponentNames.CounterChart
      },
      {
        'render-header': (h, { column }) => {
          return h(ActiveColumnHeader);
        },
        component: TableComponentNames.CounterCheckbox,
        width: 160,
        align: 'center'
      }
    ];
  }

  get state() {
    return this.$store.state.counters;
  }

  getItemLink(item) {
    return '/counters/' + encodeURIComponent(item.id) + '/';
  }

  getRecordsLink(item) {
    return `/counter-records/filter/counter=${encodeURIComponent(item.id)}&limit=20`;
  }

  rowClick({ item, column }) {
    const cellName = column.property;
    if (cellName === 'image' || cellName === 'active' || cellName === 'name2') return;
    if (cellName === 'id') {
      this.$router.push({ path: this.getItemLink(item) });
    } else {
      this.$router.push({ path: this.getRecordsLink(item) });
    }
  }

  checkboxHandler(payload) {
    this.$store
      .dispatch(this.state.Action.Update, {
        id: payload.item.id,
        [payload.prop]: payload.value
      })
      .catch((e) => {
        payload.item[payload.prop] = !payload.value;
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      });
  }

  chartClickHandler(payload) {
    this.$router.push({ path: this.getItemLink(payload.item) });
  }

  actionHandler(payload) {
    if (payload.type === CounterCheckboxEventType) {
      this.checkboxHandler(payload);
    } else if (payload.type === CounterChartClickEventType) {
      this.chartClickHandler(payload);
    }
  }

  setUpdateInterval() {
    this.updateInterval = setInterval(() => {
      this.$store.dispatch(this.state.Action.Get).catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      });
    }, 10000);
  }

  counterTypeFormatter({ multi_camera }) {
    return this.$tf(multi_camera ? 'multi_camera' : 'single_camera');
  }

  distanceFormatter(item) {
    if (item.now_proximity_min !== null && item.now_proximity_min !== undefined) {
      return item.now_proximity_min.toFixed(2) + ' / ' + item.now_proximity_avg.toFixed(2) + ' / ' + item.now_proximity_max.toFixed(2);
    }
    return '- / - / -';
  }

  mounted() {
    this.$store.dispatch('getAllCameras');
    this.setUpdateInterval();
  }

  beforeDestroy() {
    clearInterval(this.updateInterval);
  }
}
